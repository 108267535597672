import api from "@/services/api";

export default {
  fetchPendentesAvaliacao() {
    return api.get(`auto_cadastro/pendentes`).then((response) => response.data);
  },
  postAvaliacaoAutocadastro(payload) {
    return api
      .put(
        `auto_cadastro/avaliar/${payload.id_autocadastro}?validado=${payload.validado}`
      )
      .then((response) => response.data);
  },
};
