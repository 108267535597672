import api from "@/services/api";

export default {
  async getProfissionaisUnidade() {
    return api
      .get("/coord_solicitante/listar_profissionais")
      .then((response) => response.data);
  },

  async adicionarProfissional(payload) {
    return api
      .post("/coord_solicitante/cadastrar_profissional", payload)
      .then((response) => response.data);
  },

  async removerProfissional(payload) {
    return api
      .delete("/coord_solicitante/remover_profissional", {
        params: {
          vinculo_id: payload,
        },
      })
      .then((response) => response.data);
  },

  async fetchPessoa(payload) {
    return api
      .post("/integracoes/pessoa", payload)
      .then((response) => response.data);
  },
  async fetchPerfis() {
    return api.get("/perfis/perm_cadastro").then((response) => response.data);
  },
  async fetchOcupacoes(id) {
    return api
      .get(`/integracoes/ocupacoes`, {
        params: {
          user_id: id,
        },
      })
      .then((response) => response.data);
  },
};
