import coordUnidadeSaudeService from "@/services/coordenadorUnidadeSaude";

const state = {
  listaProfissionais: [],
  statusCadastro: false,
  statusRemocao: null,
  pessoa: null,
  perfis: [],
  ocupacoes: [],
};

const getters = {
  getListaProfissionais: (state) => state.listaProfissionais,
  getStatusCadastro: (state) => state.statusCadastro,
  getPessoa: (state) => state.pessoa,
  getPerfis: (state) => state.perfis,
  getOcupacoes: (state) => state.ocupacoes,
  getStatusRemocao: (state) => state.statusRemocao,
};

const actions = {
  async getProfissionais({ commit }) {
    return await coordUnidadeSaudeService
      .getProfissionaisUnidade()
      .then((response) => {
        commit("setListaProfissionais", response);
      });
  },

  async getPessoaPorCPF({ commit }, id) {
    return await coordUnidadeSaudeService.fetchPessoa(id).then((response) => {
      commit("setPessoa", response);
    });
  },
  setarPessoa({ commit }, pessoa) {
    commit("setPessoa", pessoa);
  },

  async fetchPerfis({ commit }) {
    return await coordUnidadeSaudeService.fetchPerfis().then((response) => {
      commit("setPerfis", response);
    });
  },

  async fetchOcupacoes({ commit }, id) {
    return await coordUnidadeSaudeService
      .fetchOcupacoes(id)
      .then((response) => {
        commit("setOcupacoes", response);
      });
  },

  limpaVariaveis({ commit }) {
    commit("setPessoa", null);
    commit("setStatusCadastro", false);
    commit("setStatusRemocao", null);

    commit("setOcupacoes", []);
  },

  async adicionarProfissional({ commit }, payload) {
    await coordUnidadeSaudeService
      .adicionarProfissional(payload)
      .then((response) => {
        commit("setStatusCadastro", response);
      })
      .catch((err) => {
        console.log(err);
        commit("setStatusCadastro", "erro");
      });
  },

  async removerProfissional({ commit }, payload) {
    await coordUnidadeSaudeService
      .removerProfissional(payload)
      .then((response) => {
        commit("setStatusRemocao", response);
      })
      .catch((err) => {
        commit("setStatusRemocao", err);
      });
  },
};

const mutations = {
  setListaProfissionais(state, data) {
    state.listaProfissionais = data;
  },
  setStatusCadastro(state, status) {
    state.statusCadastro = status;
  },

  setStatusRemocao(state, status) {
    state.statusRemocao = status;
  },
  setPessoa(state, pessoa) {
    state.pessoa = pessoa;
  },
  setPerfis(state, perfis) {
    state.perfis = perfis;
  },
  setOcupacoes(state, ocupacoes) {
    state.ocupacoes = ocupacoes;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
