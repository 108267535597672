import api from "@/services/api";

export default {
  async fetchPacientesUnidade() {
    return await api
      .get(`painel_solicitante/lista_andamento_subgrupo?meus_pacientes=false`)
      .then((response) => response.data);
  },
  async fetchMeusPacientes() {
    return await api
      .get(`painel_solicitante/lista_andamento_subgrupo?meus_pacientes=true`)
      .then((response) => response.data);
  },
  async fetchListaSolicitacao(payload) {
    return await api.get("painel_solicitante/lista_andamento", {
      params: {
        subgrupo: payload.subgrupo,
      },
    });
  },
};
