import Vue from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import HighchartsVue from "highcharts-vue";
import VueAxios from "vue-axios";
import VueAuthenticate from "vue-authenticate";
import axios from "axios";
import moment from "moment";

Vue.prototype.moment = moment;

Vue.use(VueAxios, axios);
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_API_URL, // Your API domain
  timeout: 5000,

  providers: {
    sabia: {
      tokenName: "access",
      tokenPath: "access",
      name: "sabia",
      clientId: "7WdOzTwdvediMZIDUNPvQs2xeHAZ59Pm0CRiTnPq",
      url: "/api/auth/sabia",
      authorizationEndpoint: "https://login.sabia.ufrn.br/oauth/authorize",
      redirectUri: process.env.VUE_APP_CALLBACK, // Your client app URL
      oauthType: "2.0",
    },
  },
});

Vue.use(HighchartsVue);

//modules external
import VueMask from "v-mask";
import VCalendar from "v-calendar";
import "popper.js/dist/popper.min.js";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import {
  ValidationObserver,
  ValidationProvider,
  extend,
  localize,
} from "vee-validate";
import br from "vee-validate/dist/locale/pt_BR.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

localize("en", br);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

import VueNotifications from "vue-notifications";
import iziToast from "izitoast"; // https://github.com/dolce/iziToast
import "izitoast/dist/css/iziToast.min.css";

function toast({ title, message, type, timeout, cb, position }) {
  if (type === VueNotifications.types.warn) type = "warning";
  return iziToast[type]({ title, message, timeout, cb, position });
}
const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

Vue.config.productionTip = false;

Vue.use(VCalendar);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMask);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
