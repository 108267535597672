import suporteService from "@/services/suporteService.js";

const state = {
  listasPendentesAvaliacao: [],
  statusAvaliacao: null,
  isLoading: false,
};

const getters = {
  getPendentesAvaliacao(state) {
    return state.listasPendentesAvaliacao;
  },
  getStatusAvaliacao(state) {
    return state.statusAvaliacao;
  },
};

const actions = {
  fetchPendentesAvaliacao({ commit, state }) {
    state.isLoading = !state.isLoading;
    suporteService
      .fetchPendentesAvaliacao()
      .then((payload) => {
        commit("setPendentesAvaliacao", payload);
      })
      .finally(() => {
        state.isLoading = !state.isLoading;
      });
  },
  async postAvaliacaoAutocadastro({ commit }, payload) {
    await suporteService
      .postAvaliacaoAutocadastro(payload)
      .then((response) => {
        commit("setStatusAvaliacao", response.detail.success);
      })
      .catch((err) => {
        console.log(err);
        commit("setStatusAvaliacao", err.response.data);
      });
  },
};

const mutations = {
  setPendentesAvaliacao(state, payload) {
    state.listasPendentesAvaliacao = payload;
  },
  setStatusAvaliacao(state, status) {
    state.statusAvaliacao = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
