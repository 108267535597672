const state = {
  fields: [
    {
      key: "data",
      label: "Data",
    },
    {
      key: "item",
      label: "Itens",
      sortable: false,
      tdClass: "align-middle",
    },
    {
      key: "horario",
      label: "Horário",
      sortable: false,
      tdClass: "align-middle",
    },
  ],
  items: [],
  funcao: null,
};

const getters = {
  getFields: (state) => state.fields,
  getItems: (state) => state.items,
};

const actions = {
  setItems({ commit }, items) {
    commit("setItems", items);
  },
};

const mutations = {
  setFields(state, data) {
    state.fields = data;
  },
  setItems(state, data) {
    state.items = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
