import api from "@/services/api";
import store from "@/store";

export default {
  async validarTotpToken(payload) {
    const objeto = {
      totp_key: store.getters["auth/getTokenTotp"].token_key,
      totp_token: payload,
    };
    return await api
      .post("/auth/totp_token", objeto)
      .then((response) => response.data);
  },

  cadastrarUsuario(payload) {
    return api
      .post("/auto_cadastro/submeter", payload)
      .then((response) => response.data);
  },
  getUsuarioLogado() {
    return api
      .get("/integracoes/pessoa_logada")
      .then((response) => response.data);
  },

  getConselhos() {
    return api.get("/integracoes/conselhos").then((response) => response);
  },

  getEstados() {
    return api.get("/integracoes/estados").then((response) => response);
  },
  validarProfissional(payload) {
    return api.post("/integracoes/cfm", payload).then((response) => response);
  },
  logout() {
    return api.get("auth/logout").then((response) => response);
  },
};
