import api from "@/services/api";

export default {
  async fetchSolicitacoes(url) {
    return await api.get(url).then((response) => response.data);
  },

  async fetchCards() {
    return await api
      .get(`/painel_profissional_referencia/cards`)
      .then((response) => response.data);
  },

  atualizaDados() {
    this.fetchCards();
  },
  async setAvaliarSolicitacao(payload) {
    return await api
      .post(`/profissional_referencia/avaliar_solicitacao`, {
        solicitacao_id: payload.solicitacao_id,
        decisao: payload.decisao,
        justificativa: payload.justificativa,
        classificacao: payload.classificacao,
      })
      .then((response) => response.data);
  },
  async setAgendamento(payload) {
    return await api
      .post(`/profissional_referencia/agendar_solicitacao`, {
        solicitacao_id: payload.solicitacao_id,
        modalidade: payload.modalidade,
        horario: payload.horario,
      })
      .then((response) => response.data);
  },
  async getLinkConsulta(id) {
    return await api.get("/profissional_referencia/link_telemedicina", {
      params: {
        solicitacao_id: id,
      },
    });
  },
  async desautorizar_solicitacao(data) {
    return await api.put(
      "profissional_referencia/desautorizar_solicitacao",
      data
    );
  },
};
