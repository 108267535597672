import api from "@/services/api";

export default {
  fetchProfissionais() {
    return api
      .get(`grade_profissionais/uni_referencia`)
      .then((response) => response.data);
  },
  editPerfil(data) {
    return api.put(`perfil/editar`, data);
  },
  getMeusDados() {
    return api.get(`perfil/meus_dados`).then((response) => response.data);
  },
  getDetalharProfissional(id) {
    return api
      .get("grade_profissionais/detalhar_profissional", {
        params: { vinculo_id: id },
      })
      .then((response) => response.data);
  },
  fetchData(titleTab) {
    return api
      .get(
        `${
          titleTab === "exames"
            ? "grade_profissionais/credenciamento_exames"
            : "grade_profissionais/credenciamento_consultas"
        }`
      )
      .then((response) => response.data);
  },
};
