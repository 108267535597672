<template>
  <div
    class="bg-gray-50 d-lg-flex flex-column sub-header shadow-sm p-4 p-lg-0 mb-4 flex-md-row justify-content-md-between"
  >
    <div class="d-lg-flex aux-sub-header">
      <div class="text-center text-md-left d-flex align-items-center">
        <img
          src="@/assets/images/icons/healthicons_nurse-outline.svg"
          width="27"
          class="ml-md-3"
          alt=""
        />
        <span class="text-truncate max-size-width">{{
          getVinculoSelecionado.perfil
        }}</span>
      </div>

      <div class="text-center text-md-left d-flex align-items-center">
        <img
          src="@/assets/images/icons/icon-map-pin-gray.svg"
          width="27"
          class="ml-md-3"
          alt=""
        />
        <span class="text-truncate max-size-width">{{
          getVinculoSelecionado.estabelecimento
        }}</span>
      </div>

      <h4 class="mb-0 font-normal my-2 my-lg-0 ml-md-3" v-if="getAmbiente">
        <b-badge pill class="badge-danger-regula" variant="danger">
          <b-icon-exclamation-triangle-fill
            class="mr-2"
          ></b-icon-exclamation-triangle-fill
          >Atenção! Ambiente de TREINAMENTO</b-badge
        >
      </h4>
    </div>
    <div
      class="d-flex justify-content-between mt-3 mt-md-0 mr-md-3 aux-sub-header"
    >
      <button
        class="btn btn-change-green1"
        @click="$router.push({ name: 'escolhervinculo' })"
      >
        <img
          src="@/assets/images/icons/icon-change.svg"
          width="27"
          class="mr-md-2"
          alt=""
        />Alterar Vínculo
      </button>
      <div v-if="getUser" class="align-items-center d-flex d-md-none">
        <button
          class="btn text-main-secondary fw-semibold d-flex ml-1 px-0"
          @click="logout"
        >
          Sair
          <img src="@/assets/images/icons/icon-logoff.svg" alt="" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SubHeader",
  data() {
    return {
      id_vinculoSelecionado: null,
      vinculoState: null,
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getVinculoSelecionado",
      "getUser",
      "getVinculos",
      "getAmbiente",
    ]),
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style lang="scss" scoped src="./styles.scss" />
