import painelSolicitanteService from "@/services/painelSolicitante.service";

const state = {
  oncologicoPacientesUnidade: [],
  oncologicoMeusPacientes: [],
  totalOncologicoPacientesUnidade: 0,
  totalOncologicoMeusPacientes: 0,

  elePacientesUnidade: [],
  eleMeusPacientes: [],
  totalElePacientesUnidade: 0,
  totalEleMeusPacientes: 0,

  intPacientesUnidade: [],
  intMeusPacientes: [],
  totalIntPacientesUnidade: 0,
  totalIntMeusPacientes: 0,

  listaSolicitacao: {
    ele: {
      dados: [],
      total: 0,
    },
    int: {
      dados: [],
      total: 0,
    },
    onco: {
      dados: [],
      total: 0,
    },
  },
  labelSubgrupo: "",
};

const getters = {
  getOncologicoPacientesUnidade(state) {
    return state.oncologicoPacientesUnidade;
  },
  getOncologicoMeusPaciente(state) {
    return state.oncologicoMeusPacientes;
  },
  getTotalOncologico(state) {
    return state.totalOncologicoPacientesUnidade;
  },
  getTotalOncologicoMeusPacientes(state) {
    return state.totalOncologicoMeusPacientes;
  },

  getElePacientesUnidade(state) {
    return state.elePacientesUnidade;
  },
  getEleMeusPaciente(state) {
    return state.eleMeusPacientes;
  },
  getTotalEle(state) {
    return state.totalElePacientesUnidade;
  },
  getTotalEleMeusPacientes(state) {
    return state.totalEleMeusPacientes;
  },

  getIntPacientesUnidade(state) {
    return state.intPacientesUnidade;
  },
  getIntMeusPaciente(state) {
    return state.intMeusPacientes;
  },
  getTotalInt(state) {
    return state.totalIntPacientesUnidade;
  },
  getTotalIntMeusPacientes(state) {
    return state.totalIntMeusPacientes;
  },

  getListaSolicitacao(state) {
    return state.listaSolicitacao;
  },
  getTotalOncoListaSolicitacao(state) {
    return state.listaSolicitacao.onco.total;
  },
  getLabelSubgrupo(state) {
    return state.labelSubgrupo;
  },
};

const actions = {
  fetchPacientesUnidade({ commit }) {
    painelSolicitanteService.fetchPacientesUnidade().then((payload) => {
      commit("setOncologicoPacientesUnidade", payload.onco.dados);
      commit("setTotalOncologicoPacientesUnidade", payload.onco.total);

      commit("setElePacientesUnidade", payload.ele.dados);
      commit("setTotalElePacientesUnidade", payload.ele.total);

      commit("setIntPacientesUnidade", payload.int.dados);
      commit("setTotalIntPacientesUnidade", payload.int.total);
    });
  },
  fetchMeusPacientes({ commit }) {
    painelSolicitanteService.fetchMeusPacientes().then((payload) => {
      commit("setOncologicoMeusPacientes", payload.onco.dados);
      commit("setTotalOncologicoMeusPacientes", payload.onco.total);

      commit("setIntMeusPacientes", payload.int.dados);
      commit("setIntTotalMeusPaciente", payload.int.total);

      commit("setEleMeusPacientes", payload.ele.dados);
      commit("setEleTotalMeusPacientes", payload.ele.total);
    });
  },

  async fetchListaSolicitacao({ commit }, payload) {
    await painelSolicitanteService
      .fetchListaSolicitacao(payload)
      .then((response) => {
        commit("setListaSolicitacao", response.data);
        commit("setLabelSubgrupo", payload.subgrupo);
      });
  },
};

const mutations = {
  setOncologicoPacientesUnidade(state, payload) {
    state.oncologicoPacientesUnidade = payload;
  },
  setOncologicoMeusPacientes(state, payload) {
    state.oncologicoMeusPacientes = payload;
  },
  setTotalOncologicoPacientesUnidade(state, payload) {
    state.totalOncologicoPacientesUnidade = payload;
  },
  setTotalOncologicoMeusPacientes(state, payload) {
    state.totalOncologicoMeusPacientes = payload;
  },

  setElePacientesUnidade(state, payload) {
    state.elePacientesUnidade = payload;
  },
  setEleMeusPacientes(state, payload) {
    state.eleMeusPacientes = payload;
  },
  setTotalElePacientesUnidade(state, payload) {
    state.totalElePacientesUnidade = payload;
  },
  setEleTotalMeusPacientes(state, payload) {
    state.totalEleMeusPacientes = payload;
  },

  setIntPacientesUnidade(state, payload) {
    state.intPacientesUnidade = payload;
  },
  setIntMeusPacientes(state, payload) {
    state.intMeusPacientes = payload;
  },
  setTotalIntPacientesUnidade(state, payload) {
    state.totalIntPacientesUnidade = payload;
  },
  setIntTotalMeusPaciente(state, payload) {
    state.totalIntMeusPacientes = payload;
  },

  setListaSolicitacao(state, payload) {
    state.listaSolicitacao = payload;
  },
  setLabelSubgrupo(state, payload) {
    state.labelSubgrupo = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
