const state = {
  id: null,
};

const getters = {
  getId: (state) => state.id,
};

const actions = {
  salvaId({ commit }, id) {
    commit("setId", id);
  },
};

const mutations = {
  setId(state, id) {
    state.id = id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
