import VueNotifications from "vue-notifications";

export default {
  notifications: {
    showMessageError: {
      title: "Erro!",
      message: "Algo de errado aconteceu, tente novamente mais tarde.",
      type: VueNotifications.types.error,
      position: "topCenter",
    },
    showSuccess: {
      title: "Sucesso!",
      message: "Operação realizada com sucesso.",
      type: VueNotifications.types.success,
      position: "topCenter",
    },
    showMessageWarning: {
      title: "Atenção",
      message: "Operação realizada com sucesso.",
      type: "info",
      position: "topCenter",
    },
  },
};
