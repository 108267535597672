import api from "@/services/api";

export default {
  async fetchDadosMedico(payload) {
    return await api
      .post(`integracoes/cfm`, payload)
      .then((response) => response.data);
  },
  async fetchDadosReferencia(payload) {
    return await api
      .get("coord_referencia/buscar_referencia", {
        params: {
          item_id: payload.item_id,
          tipo_solicitacao: payload.tipo_solicitacao,
          paciente_id: payload.paciente_id,
          sedacao: payload.sedacao,
          estab_solicitante_id: payload.estab_solicitante_id,
        },
      })
      .then((response) => response.data);
  },
  async fetchDadosPaciente(payload) {
    return api
      .post(`integracoes/pessoa`, payload)
      .then((response) => response.data);
  },
  async fetchCid(payload) {
    return await api
      .get(`integracoes/cid?q=${payload}`)
      .then((response) => response.data);
  },
  async fetchProcedimentos(payload) {
    return await api
      .get("procedimentos/buscar?q=" + payload)
      .then((response) => response.data);
  },
  fetchPrioridades() {
    return api
      .get(`solicitacoes/classificacao?codigo=02`)
      .then((response) => response.data);
  },
  async uploadFiles(payload) {
    return await api
      .post("/arquivo/upload", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => response.data);
  },
  async novaSolicitacaoAmbulatorial(payload) {
    return await api
      .post("/solicitacoes/exames/nova", payload)
      .then((response) => response.data);
  },
  async novaSolicitacaoAmbulatorialJuridica(payload) {
    return await api
      .post("/juridico/solicitar/procedimento", payload)
      .then((response) => response.data);
  },
  async novaSolicitacaoConsulta(payload) {
    return await api
      .post("/solicitacoes/consulta/nova", payload)
      .then((response) => response.data);
  },

  async novaSolicitacaoConsultaJuridica(payload) {
    return await api
      .post("/juridico/solicitar/consulta", payload)
      .then((response) => response.data);
  },
  async phoneIsValid(payload) {
    return await api
      .post("/telefone/validar_token", payload)
      .then((response) => response.data);
  },
  async sendSMSToken(payload) {
    return await api
      .post("/telefone/enviar_token", payload)
      .then((response) => response.data);
  },
};
