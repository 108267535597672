import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import localForage from "localforage";
const vuexLocal = new VuexPersistence({
  storage: localForage,
  asyncStorage: true,
  modules: ["auth"],
});

Vue.use(Vuex);

//import modules above this line
import solicitacaoAmbulatorial from "./modules/solicitacaoAmbulatorial";
import cardsRow from "./modules/cardsRow";
import auth from "./modules/auth";
import painelSolicitante from "./modules/painelSolicitante";
import quadroClinico from "./modules/quadroClinico";
import dataPage from "./modules/dataPage";
import suporte from "./modules/perfilSuporte";
import contrato from "./modules/contrato";
import consultas from "./modules/consultas";
import profissionais from "./modules/profissionais";
import erros from "./modules/erros";
import coordenadorUnidadeSaude from "./modules/coordenadorUnidadeSaude";
import painelProfReferencia from "./modules/painelProfReferencia";
import agenda from "./modules/agenda";
import dadosUnidade from "./modules/dadosUnidade";
import npsForm from "./modules/npsForm";

export default new Vuex.Store({
  modules: {
    solicitacaoAmbulatorial,
    cardsRow,
    auth,
    agenda,
    painelSolicitante,
    quadroClinico,
    dataPage,
    suporte,
    contrato,
    coordenadorUnidadeSaude,
    painelProfReferencia,
    consultas,
    profissionais,
    erros,
    dadosUnidade,
    npsForm,
  },
  plugins: [vuexLocal.plugin],
});
