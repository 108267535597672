<template>
  <div>
    <div class="sidebar-bottom d-lg-none d-flex align-items-center">
      <div class="d-flex d-md-none align-items-center buttons-mobile">
        <img
          :src="getUser.pessoa.avatar"
          class="align-self-center rounded-circle ml-2"
          width="36"
          alt=""
        />
        <span class="d-flex ml-2">
          <span>{{ getUser.first_name }}</span>
        </span>
      </div>
    </div>

    <button
      id="burger"
      :class="`d-block d-lg-none open-main-nav ${open ? 'is-open' : ''}`"
      @click="openMenu"
    >
      <span class="burger"></span>
      <span class="burger-text">Menu</span>
    </button>

    <nav :class="`main-nav ${open ? 'is-open' : ''}`" id="main-nav">
      <MenuGeneral />
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuGeneral from "@/components/SidebarLeft/Menu/MenuGeneral.vue";

export default {
  name: "BottomBar",
  components: {
    MenuGeneral,
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
  methods: {
    openMenu() {
      this.open = !this.open;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/variables.scss";
.sidebar-bottom {
  position: fixed;
  z-index: 10;
  bottom: 0;
  height: 60px;
  background-color: white;
  width: 100%;
  box-shadow: 0px -4px 4px rgba(68, 68, 68, 0.09);
}

.main-nav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  text-align: center;
  background: #fff;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  transition: all 0.375s;
}

.main-nav.is-open {
  opacity: 1;
  z-index: 100;
  visibility: visible;
}

/* Yellow band effect */
.main-nav::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: -15px;
  background: $green1;
  transform-origin: 0 0;
  transform: skew(-14deg) translateX(-120%);
  transition: all 0.275s 0.1s;
}

.main-nav.is-open::before {
  transform: skew(-14deg) translateX(0);
}

/* Skewing effect on menu links */
.main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 93%; /* Should be 100%, but we have a notice message :D */
  align-items: flex-end;
  justify-content: center;
  transform: translateX(-18%) skew(-16deg);
}

.main-nav li {
  display: block;
  margin: 0.5rem 0;
  text-align: right;
  transform: skew(16deg);
}

/* Apparition effect on links */
.main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}
.main-nav li:nth-child(1) a {
  transition: all 275ms 175ms;
}
.main-nav li:nth-child(2) a {
  transition: all 275ms 225ms;
}
.main-nav li:nth-child(3) a {
  transition: all 275ms 275ms;
}
.main-nav li:nth-child(4) a {
  transition: all 275ms 325ms;
}
.main-nav li:nth-child(5) a {
  transition: all 275ms 375ms;
}

/* Decoration */
.main-nav ul,
.main-nav li {
  list-style: none;
  padding: 0;
}
.main-nav a {
  display: block;
  padding: 12px 0;
  color: $main-secondary;
  font-size: 1.4em;
  text-decoration: none;
  font-weight: bold;
}

.open-main-nav {
  position: fixed;
  bottom: 7px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;
}
.open-main-nav:focus {
  outline: none;
}
.burger {
  position: relative;
  display: block;
  width: 28px;
  height: 4px;
  margin: 0 auto;
  background: $main-secondary;
  transform: skew(5deg);
  transition: all 0.275s;
}

.burger:after,
.burger:before {
  content: "";
  display: block;
  height: 100%;
  background: $main-secondary;
  transition: all 0.275s;
}

/* MENU Text part */

.burger-text {
  display: block;
  font-size: 0.675rem;
  letter-spacing: 0.05em;
  margin-top: 0.5em;
  text-transform: uppercase;
  font-weight: 500;
  text-align: center;
  color: $main-secondary;
}

.burger:after {
  transform: translateY(-12px);
}

.burger:before {
  transform: translateY(-16px);
}

/* Toggle State part */
.is-open .burger {
  transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
  transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
  transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}
</style>
