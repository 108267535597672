import api from "@/services/api";

const state = {
  items: {
    bairro: "",
    cep: "",
    cidade: "",
    complemento: "",
    estado: "",
    ibge: "",
    logradouro: "",
    numero: "",
    telefone: "",
    unidades: [],
  },
};

const getters = {
  getItems: (state) => {
    return state.items;
  },
  getUnidadesSize: (state) => {
    return state.items.unidades;
  },
};

const actions = {
  async fetchData({ commit, state }) {
    state.items = [];
    await api.get(`/dados_unidade/dados_unidade`).then((response) => {
      commit("setItem", response.data);
    });
  },
  addUnidade({ state }) {
    state.items.unidades.push({
      nome: "Nova unidade",
    });
  },
  deleteItem({ commit }, item) {
    commit("deleteItem", item);
  },
};

const mutations = {
  setItem: (state, data) => {
    state.items = data;
  },
  setBairro: (state, data) => {
    state.items.bairro = data.value;
  },
  setLogradouro: (state, data) => {
    state.items.logradouro = data.value;
  },
  setNumero: (state, data) => {
    state.items.numero = data.value;
  },
  setComplemento: (state, data) => {
    state.items.complemento = data.value;
  },
  setEstado: (state, data) => {
    state.items.estado = data.value;
  },
  setCep: (state, data) => {
    state.items.cep = data.value;
  },
  setTelefone: (state, data) => {
    state.items.telefone = data.value;
  },
  setCidade: (state, data) => {
    state.items.cidade = data.value;
  },
  setIbge: (state, data) => {
    state.items.ibge = data.value;
  },
  setNomeUnidade: (state, data) => {
    state.items.unidades[data.index].nome = data.value;
  },
  deleteItem: (state, item) => {
    state.items.unidades.splice(item, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
