<template>
  <div
    :class="`h-100 ${
      $route.name === 'home' || $route.name === 'token' ? 'bg-home' : ''
    }`"
  >
    <keep-alive>
      <HeaderGlobal class="position-fixed w-100" />
    </keep-alive>
    <div
      v-if="$route.name === 'home' || $route.name === 'token'"
      class="h-100 d-flex align-items-center flex-column"
    >
      <div
        class="d-flex flex-column align-items-center justify-content-center h-100"
      >
        <img
          src="@/assets/images/acessa_confia_logo.png"
          alt=""
          class="img-fluid px-4 px-lg-0"
        />

        <router-view />
      </div>
      <div class="d-flex justify-content-end w-100">
        <CopyrightLais class="mb-3 mr-5" v-once />
      </div>
    </div>
    <div v-else class="h-100">
      <router-view />
    </div>
  </div>
</template>

<script>
import HeaderGlobal from "@/components/HeaderGlobal/HeaderGlobal.vue";
import CopyrightLais from "@/components/CopyrightLais/CopyrightLais.vue";

export default {
  name: "PageWithHeaderGlobal",
  components: {
    HeaderGlobal,
    CopyrightLais,
  },
};
</script>

<style lang="scss" scoped src="./styles.scss" />
