import api from "@/services/api";

const state = {
  dataCards: [],
  isActive: null,
  atualizarCards: false,
};

const getters = {
  getCards: (state) => {
    return state.dataCards;
  },
  getIsActive: (state) => state.isActive,
  getCardsPagina: (state) => state.atualizarCards,
};

const actions = {
  async fetchAllCards({ commit }, payload) {
    const response = await api.get(`${payload.url}`, {
      params: {
        meus_pacientes: payload.is_true,
      },
    });
    commit("setCards", response.data);
    commit("setAtualizaCards");
  },
};

const mutations = {
  setAtualizaCards: (state) => {
    state.atualizarCards = !state.atualizarCards;
  },
  setCards: (state, data) => {
    state.dataCards = data;
  },
  setIsActive: (state, data) => {
    state.isActive = data;
  },
  removeIsActive: (state) => {
    state.isActive = null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
