import router from "@/router";
import api from "@/services/api";
import serviceAutocadastro from "@/services/autocadastro";
import { jwtDecrypt, tokenAlive } from "@/helpers/jwtHelper";

const state = () => ({
  activeModal: false,
  authData: {
    token: null,
    refreshToken: "",
    tokenExp: "",
    userId: "",
  },
  ambienteUtilizado: null,
  cadastroAtivo: null,
  statusTokenPublico: "",
  statusSubmissaoAutocadastro: null,
  user: {
    first_name: "",
    last_name: "",
    pessoa: {
      avatar: "",
      raca_cor: "",
      sexo: "",
      totp_ativo: null,
    },
  },
  name_social: "",
  loginStatus: "",
  loginLoading: false,
  loginError: "",
  vinculos: [],
  vinculoSelecionado: null,
  tokenTotp: {
    message: "",
    data: {
      detail: {
        validado: false,
      },
    },
  },
  conselhos: [],
  estados: [],
  statusMedico: [],
  vinculosPendentes: [],
  loadingRegistroConselho: false,
});

const getters = {
  getStatusTokenPublico(state) {
    return state.statusTokenPublico;
  },
  getLoginStatus(state) {
    return state.loginStatus;
  },
  getAuthData(state) {
    return state.authData;
  },
  isTokenActive(state) {
    if (!state.authData.tokenExp) {
      return false;
    }
    return tokenAlive(state.authData.tokenExp);
  },
  getUser(state) {
    return state.user;
  },
  getFazLogin(state) {
    return state.user.pessoa.faz_login;
  },
  getVinculoSelecionado(state) {
    return state.vinculoSelecionado;
  },
  getVinculos(state) {
    return state.vinculos;
  },
  getTokenTokenTotp(state) {
    return state.tokenTotp;
  },
  getLoading(state) {
    return state.loginLoading;
  },
  getLoginError(state) {
    return state.loginError;
  },
  getTokenTotp(state) {
    return state.tokenTotp;
  },
  getCodigoTokenTotp(state) {
    return state.codigoTokenTotp;
  },
  getTokenTotpStatus(state) {
    return state.tokenTotpStatus;
  },
  getStatusSubmissaoAutocadastro(state) {
    return state.statusSubmissaoAutocadastro;
  },
  getAmbiente(state) {
    return state.ambienteUtilizado;
  },
  getConselhos(state) {
    return state.conselhos;
  },
  getEstados(state) {
    return state.estados;
  },
  getStatusMedico(state) {
    return state.statusMedico;
  },
  getCadastroAtivo(state) {
    return state.cadastroAtivo;
  },
  getVinculosPendentes(state) {
    return state.vinculosPendentes;
  },
  getFullName(state) {
    return `${state.user.first_name} ${state.user.last_name}`;
  },
  getNameSocial(state) {
    return state.name_social;
  },
};

const actions = {
  login({ commit }, response) {
    commit("clearUserData");
    commit("setLoginError", "");
    commit("setLoginLoading", true);
    if (response && response.data) {
      commit("saveTokenData", response.data);
      commit("setLoginLoading", false);
      commit("setLoginStatu", "success");
      commit("setCadastroAtivo", response.data.user.pessoa.faz_login);
      commit("setAmbiente", response.data.treinamento);
    } else {
      commit("setLoginLoading", false);
      commit("setLoginStatu", "failed");
    }
  },
  async refresh({ commit, state }) {
    const response = await api
      .post("auth/refresh", { refresh: state.authData.refreshToken })
      .catch((err) => {
        console.log(err);
      });
    if (response && response.data) {
      commit("saveTokenData", response.data);
      commit("setLoginStatu", "success");
    } else {
      commit("setLoginStatu", "failed");
    }
  },
  async validateTotpToken({ commit }, payload) {
    const response = await api
      .post("auth/totp_token_user", payload)
      .catch((err) => {
        return err;
      });

    if (response && response.data) {
      commit("setTokenTotp", {
        message: "success",
        data: response.data,
      });
    } else {
      commit("setTokenTotp", {
        message: "failed",
        data: "",
      });
    }
  },
  async obterTotpToken({ commit }) {
    commit("setStatusTokenPublico", null);
    const response = await api.get("auth/totp_token_user").catch((err) => {
      console.log(err);
    });

    if (response && response.data) {
      commit("setTokenTotp", response.data);
    }
  },
  async validarTotpToken({ commit }, payload) {
    await api
      .post("auth/totp_token_user", payload)
      .then((response) => {
        commit("setStatusTokenPublico", response.status);
      })
      .catch((err) => {
        console.log(err);
        commit("setStatusTokenPublico", err.response.data);
      });
  },
  async logout({ commit }) {
    const response = await api.get("auth/logout").catch((err) => {
      console.log(err);
    });

    if (response.status === 200) {
      commit("clearUserData");
      await router.push("/");
    }
  },
  async fetchVinculosPendentes({ commit }) {
    const response = await api
      .get("auth/vinculos_aguardando_aprovacao")
      .catch(() => {
        commit("setVinculosPendentes", []);
      });

    if (response && response.data) {
      commit("setVinculosPendentes", response.data);
    }
  },
  async vinculos({ commit }) {
    const response = await api.get("auth/vinculos").catch(() => {
      commit("setVinculos", "error");
    });

    if (response && response.data) {
      commit("setVinculos", response.data);
    }
  },
  async definirVinculo({ commit }, payload) {
    const response = await api
      .post(`auth/definir_vinculo`, {
        id_vinculo: payload.id,
      })
      .catch((err) => {
        console.log(err);
      });

    if (response.status === 200) {
      await commit("setVinculoSelecionado", payload);
    }
  },
  async limpaVariaveis({ commit }) {
    commit("setStatusSubmissaoAutocadastro", null);
    commit("setStatusMedico", { validado: false });
  },
  async submeterAutocadastro({ commit }, payload) {
    await api
      .post("auto_cadastro/submeter", payload)
      .then((response) => {
        commit("setStatusSubmissaoAutocadastro", response.status);
      })
      .catch((err) => {
        commit("setStatusSubmissaoAutocadastro", err.response.data);
      });
  },
  async fetchConselhos({ commit }) {
    await serviceAutocadastro
      .getConselhos()
      .then((response) => {
        commit("setConselhos", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("setConselhos", []);
      });
  },
  async fetchEstados({ commit }) {
    await serviceAutocadastro
      .getEstados()
      .then((response) => {
        commit("setEstados", response.data);
      })
      .catch((err) => {
        console.log(err);
        commit("setEstados", []);
      });
  },
  async validarProfissional({ commit, state }, payload) {
    state.loadingRegistroConselho = true;
    await serviceAutocadastro
      .validarProfissional(payload)
      .then((response) => {
        commit("setStatusMedico", response.data);
      })
      .finally(() => {
        state.loadingRegistroConselho = false;
      });
  },
  sessionExpired({ commit }) {
    commit("clearUserData");
    router.push("/");
    setTimeout(() => {
      commit("setActiveModal", true);
    }, 200);
  },
};

const mutations = {
  saveTokenData(state, data) {
    const user = data.user;
    const jwtDecodedValue = jwtDecrypt(data.access);
    const newTokenData = {
      token: data.access,
      refreshToken: data.refresh,
      tokenExp: jwtDecodedValue.exp,
      userId: jwtDecodedValue.user_id,
    };

    state.user = user;
    state.authData = newTokenData;
  },
  setConselhos(state, conselhos) {
    state.conselhos = conselhos;
  },
  setVinculosPendentes(state, vinculos) {
    state.vinculosPendentes = vinculos;
  },
  setEstados(state, estados) {
    state.estados = estados;
  },
  setStatusMedico(state, status) {
    return (state.statusMedico = status);
  },
  setStatusTokenPublico(state, status) {
    state.statusTokenPublico = status;
  },
  saveAccessToken(state, token) {
    state.authData.token = token;
  },
  setLoginStatu(state, value) {
    state.loginStatus = value;
  },
  setAllVinculos(state, vinculos) {
    state.vinculos = vinculos;
  },
  setStatusSubmissaoAutocadastro(state, status) {
    state.statusSubmissaoAutocadastro = status;
  },
  setCadastroAtivo(state, ativo) {
    state.cadastroAtivo = ativo;
  },
  clearUserData(state) {
    state.authData = {
      token: null,
      refreshToken: "",
      tokenExp: "",
      userId: "",
    };
    state.user = {
      first_name: "",
      last_name: "",
      pessoa: "",
      vinculos: [],
    };
    state.name_social = "";
    state.vinculoSelecionado = {
      estabelecimento: "",
      id: "",
      perfil: "",
    };
    state.loginStatus = "";
    (state.tokenTotp = {
      message: "",
      data: {
        detail: {
          validado: false,
        },
      },
    }),
      localStorage.removeItem("vue-authenticate.vueauth_token");
    indexedDB.deleteDatabase("localforage");
  },
  setVinculoSelecionado(state, vinculoSelecionado) {
    state.vinculoSelecionado = vinculoSelecionado;
  },
  setTokenTotp(state, value) {
    state.tokenTotp = value;
  },
  setVinculos(state, vinculos) {
    if (vinculos === "error") {
      state.vinculos = [];
    } else {
      state.vinculos = vinculos;
    }
  },
  setLoginLoading(state, value) {
    state.loginLoading = value;
  },
  setLoginError(state, value) {
    state.loginError = value;
  },
  setCodigoTokenTotp(state, token) {
    state.codigoTokenTotp = token;
  },
  setAmbiente(state, ambiente) {
    state.ambienteUtilizado = ambiente;
  },
  setImageProfile(state, image) {
    state.user.pessoa.avatar = image;
  },
  setUserNameSocial(state, name) {
    state.name_social = name;
  },
  setActiveModal(state, value) {
    state.activeModal = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
