import gradeprofissionaisService from "@/services/gradeprofissionais.service";

const state = {
  data: {
    ocupacao: "",
    registro_profissional: "",
    telefone: "",
    email: "",
    sobre: "",
    instagram: "",
    linkedin: "",
    lattes: "",
  },
  isLoading: false,
  error: null,
};

const getters = {
  getData: (state) => {
    return state.data;
  },
};

const actions = {
  fetchData({ commit }, id) {
    commit("setLoading", true);
    return gradeprofissionaisService
      .getDetalharProfissional(id)
      .then((response) => {
        commit("setData", response);
      })
      .catch((error) => {
        commit("setError", error);
      })
      .finally(() => {
        commit("setLoading", false);
      });
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
