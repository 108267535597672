import contratoService from "@/services/contrato.service";

const state = {
  data: {
    itens: [],
  },
  dadosQuadro: {},
  tabelaPrincipal: [],
  tabelaAuxiliar: [],
  itemDados: {
    faz_sedacao: false,
    estabs_cobertos: [],
    procedimentos: [],
    especialidades: [],
    valor: "",
    capacidade_mensal: "",
    idade_minima: 0,
    idade_maxima: 0,
    tipo: "consulta",
    id: 0,
  },
  id_contrato_selecionado: 0,
};

const getters = {
  getContrato: (state) => state.data,
  getDadosQuadro: (state) => state.dadosQuadro,
  getTabelaPrincipal: (state) => state.tabelaPrincipal,
  getTabelaAuxiliar: (state) => state.tabelaAuxiliar,
  getItemDados: (state) =>
    state.itemDados.map((item) => {
      item.estabs_cobertos.map((estab) => {
        return {
          acao: estab.id,
        };
      });
      item.especialidades.map((especialidade) => {
        return {
          acao: especialidade.id,
        };
      });
      item.procedimentos.map((procedimento) => {
        return {
          acao: procedimento.id,
        };
      });
    }),
  getIdContratoAtual: (state) => state.itemDados.id,
  getIdContrato: (state) => state.id_contrato_selecionado,
};

const actions = {
  async dadosContrato({ commit }, id) {
    return await contratoService.dadosContrato(id).then((response) => {
      commit("setData", response);
      commit("setIdContratoSelecionado", id);
    });
  },
  async copiaDadosItem({ commit }, id) {
    return await contratoService.itemDados(id).then((response) => {
      commit("setItemDados", {
        ...response,
        id: id,
      });

      commit("setItemDadosEspecialidades", []);
      commit("setItemDadosProcedimentos", []);
    });
  },
  async abrirQuadro({ commit }, id) {
    return await contratoService.abrirQuadro(id).then((response) => {
      commit("setDadosQuadro", response);
    });
  },
  async itemDados({ commit }, id) {
    return await contratoService.itemDados(id).then((response) => {
      commit("setItemDados", {
        ...response,
        id: id,
      });
    });
  },
  async editItem({ commit, dispatch, state }, data) {
    return await contratoService.editItem(data).then((response) => {
      commit("setItemDados", response);
      dispatch("dadosContrato", state.id_contrato_selecionado);
    });
  },
  salvaDadosTabelas({ commit }, dadosTabelas) {
    commit("setTabelaPrincipal", dadosTabelas.tabelaPrincipal);
    commit("setTabelaAuxiliar", dadosTabelas.tabelaAuxiliar);
  },
};

const mutations = {
  setData(state, data) {
    state.data = data;
  },
  setDadosQuadro(state, data) {
    state.dadosQuadro = data;
  },
  setTabelaPrincipal(state, data) {
    state.tabelaPrincipal = data;
  },
  setTabelaAuxiliar(state, data) {
    state.tabelaAuxiliar = data;
  },
  setItemDados(state, data) {
    state.itemDados = data;
  },
  setItemDadosTipo(state, data) {
    state.itemDados.tipo = data;
  },
  setItemDadosCapacidadeMensal(state, data) {
    state.itemDados.capacidade_mensal = data;
  },
  setItemDadosValor(state, data) {
    state.itemDados.valor = data;
  },
  setItemDadosIdadeMinima(state, data) {
    state.itemDados.idade_minima = data;
  },
  setItemDadosIdadeMaxima(state, data) {
    state.itemDados.idade_maxima = data;
  },
  setItemDadosEstabsCobertos(state, data) {
    state.itemDados.estabs_cobertos = data;
  },
  setItemDadosEspecialidades(state, data) {
    state.itemDados.especialidades = data;
  },
  setItemDadosFazSedacao(state, data) {
    state.itemDados.faz_sedacao = data;
  },
  setItemDadosProcedimentos(state, data) {
    state.itemDados.procedimentos = data;
  },
  setIdContratoSelecionado(state, data) {
    state.id_contrato_selecionado = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
