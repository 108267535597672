const state = {
  agenda_id: null,
};

const getters = {
  getAgendaId(state) {
    return state.agenda_id;
  },
};

const actions = {
  salvaAgendaId({ commit }, payload) {
    commit("setAgendaId", payload);
  },
};

const mutations = {
  setAgendaId(state, payload) {
    state.agenda_id = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
