import axios from "axios";
import store from "@/store";

const api = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = store.getters["auth/getAuthData"].token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

let isRefreshing = false;

api.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const {
      config,
      response: { status, data },
    } = err;

    if (status === 401 && data.messages[0].token_type === "access") {
      if (!isRefreshing) {
        isRefreshing = true;

        let authData = store.getters["auth/getAuthData"];
        const payload = {
          refresh: authData.refreshToken,
        };

        const refreshResponse = await api
          .post("auth/refresh", payload)
          .catch(() => {
            isRefreshing = false;
            store.dispatch("auth/sessionExpired");
            return Promise.reject(config);
          });

        if (refreshResponse.status === 200) {
          isRefreshing = false;
          store.commit("auth/saveAccessToken", refreshResponse.data.access);
          return api(config);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default api;
