<template>
  <div class="bg-white1 min-vh-100 pb-5 pb-md-0 d-flex w-100">
    <keepAlive>
      <SidebarLeft v-model="changeView" />
    </keepAlive>
    <BottomBar />
    <div class="w-100 pb-5">
      <div :class="`aux-header ${changeView ? 'open-aux' : ''}`">
        <keepAlive>
          <HeaderGlobal class="position-relative" :isDashboard="isDashboard" />
        </keepAlive>
        <KeepAlive>
          <SubHeader />
        </KeepAlive>
      </div>
      <section
        :class="`container-fluid px-md-3 wrapper pb-3 ${
          changeView ? 'open-aux' : ''
        }`"
      >
        <div
          :class="`mx-auto  ${
            $route.name === 'formularioSolicitacao' ||
            $route.name === 'formularioConsulta'
              ? 'container'
              : 'max-1700'
          }`"
        >
          <router-view />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import HeaderGlobal from "@/components/HeaderGlobal/HeaderGlobal.vue";
import SubHeader from "@/components/SubHeader/SubHeader.vue";
import SidebarLeft from "@/components/SidebarLeft/SidebarLeft.vue";
import BottomBar from "@/components/BottomBar/BottomBar.vue";

export default {
  name: "DashboardLayout",
  components: {
    HeaderGlobal,
    SidebarLeft,
    SubHeader,
    BottomBar,
  },
  data() {
    return {
      isDashboard: true,
      changeView: localStorage.getItem("is_expanded") === "true",
    };
  },
  created() {
    if (this.getVinculos === []) {
      this.$router.push("/escolhervinculo");
    }
  },
  computed: {
    ...mapGetters("auth", ["getVinculos"]),
  },
};
</script>

<style lang="scss" scoped src="./styles.scss" />
