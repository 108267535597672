import painelProfReferenciaService from "@/services/painelProfReferenciaService";

const state = {
  cards: [],
  tituloPagina: "Painel Prof. Referência",
  listaSolicitacoes: [],
  url: "/painel_profissional_referencia/em_lista",
};

const getters = {
  getListaSolicitacoes(state) {
    return state.listaSolicitacoes;
  },
  getTituloPagina(state) {
    return state.tituloPagina;
  },
};

const actions = {
  fetchListaSolicitacoes({ commit, state }) {
    painelProfReferenciaService
      .fetchSolicitacoes(state.url)
      .then((response) => {
        commit("setListaSolicitacoes", response);
      });
  },

  fetchCards({ commit }) {
    painelProfReferenciaService.fetchCards().then((response) => {
      commit("setCards", response);
    });
  },
};

const mutations = {
  setListaSolicitacao(state, payload) {
    state.listaSolicitacao = payload;
  },
  setUrl(state, url) {
    state.url = url;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
