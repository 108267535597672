import solicitacaoAmbulatorialService from "@/services/formSolicitacaoAmbulatorial.service";

const state = {
  solicitacoes: {
    status: "",
    message: {},
    dados: [],
    codigo: 0,
  },
  tipoSolicitacao: null,
  items: [],
  itensSolicitados: [],
  dadosMedico: {
    status: "",
    dados: [],
  },
  dadosPaciente: {
    status: "",
    dados: [],
  },
  cid: {
    status: "",
    dados: [],
  },
  prioridades: {
    status: "",
    dados: [],
  },
};

const getters = {
  solicitacoes: (state) => {
    return state.solicitacoes;
  },
  items: (state) => {
    return state.items;
  },
  dadosMedico: (state) => {
    return state.dadosMedico;
  },
  dadosPaciente: (state) => {
    return state.dadosPaciente;
  },
  getCids: (state) => {
    return state.cid;
  },
  getTipoSolicitacao: (state) => {
    return state.tipoSolicitacao;
  },
  getPrioridades: (state) => {
    return state.prioridades;
  },
  getItensSolicitados: (state) => {
    return state.itensSolicitados;
  },
};

const actions = {
  async addDadosMedico({ commit }, payload) {
    await solicitacaoAmbulatorialService
      .fetchDadosMedico(payload)
      .then((dadosMedico) => {
        commit("addDadosMedico", {
          dados: dadosMedico,
          status: "sucesso",
        });
      })
      .catch((error) => {
        commit("addDadosMedico", {
          dados: [],
          status: error.response.data.detail,
        });
      });
  },
  async addDadosPaciente({ commit }, payload) {
    await solicitacaoAmbulatorialService
      .fetchDadosPaciente(payload)
      .then((dadosPaciente) => {
        commit("addDadosPaciente", {
          dados: dadosPaciente,
          status: "sucesso",
          response: "",
        });
      })
      .catch(() => {
        commit("addDadosPaciente", {
          dados: [],
          status: "error",
        });
      });
  },
  async addCid({ commit }, payload) {
    await solicitacaoAmbulatorialService
      .fetchCid(payload)
      .then((cid) => {
        commit("addCid", {
          dados: cid,
          status: "sucesso",
        });
      })
      .catch(() => {
        commit("addCid", {
          dados: [],
          status: "error",
        });
      });
  },
  async addPrioridades({ commit }, payload) {
    await solicitacaoAmbulatorialService
      .fetchPrioridades(payload)
      .then((prioridades) => {
        commit("addPrioridades", {
          dados: prioridades,
          status: "sucesso",
        });
      })
      .catch((error) => {
        commit("addPrioridades", {
          dados: [],
          status: error.response.data.detail,
        });
      });
  },
  async addSolicitacaoAmbulatorial({ commit }, solicitacao) {
    commit("setTipoSolicitacao", solicitacao.tipo_solicitacao);
    commit("setItemsSolicitados", solicitacao.itens_solicitados);
    await solicitacaoAmbulatorialService
      .novaSolicitacaoAmbulatorial(solicitacao)
      .then((solicitacao) => {
        commit("addSolicitacao", {
          dados: solicitacao,
          status: "sucesso",
        });
      })
      .catch((error) => {
        console.log(error);

        commit("addSolicitacao", {
          dados: [],
          status: "error",
          message: error.response.data,
          codigo: error.response.status,
        });
      });
  },

  async addSolicitacaoAmbulatorialJuridica({ commit }, solicitacao) {
    commit("setTipoSolicitacao", solicitacao.tipo_solicitacao);
    commit("setItemsSolicitados", solicitacao.itens_solicitados);
    await solicitacaoAmbulatorialService
      .novaSolicitacaoAmbulatorialJuridica(solicitacao)
      .then((solicitacao) => {
        commit("addSolicitacao", {
          dados: solicitacao,
          status: "sucesso",
        });
      })
      .catch((error) => {
        console.log(error);

        commit("addSolicitacao", {
          dados: [],
          status: "error",
          message: error.response.data,
          codigo: error.response.status,
        });
      });
  },

  async addSolicitacaoConsulta({ commit }, solicitacao) {
    commit("setTipoSolicitacao", solicitacao.tipo_solicitacao);
    commit("setItemsSolicitados", solicitacao.itens_solicitados);
    await solicitacaoAmbulatorialService
      .novaSolicitacaoConsulta(solicitacao)
      .then((solicitacao) => {
        commit("addSolicitacaoConsulta", {
          dados: solicitacao,
          status: "sucesso",
        });
      })
      .catch((error) => {
        console.log(error);
        commit("addSolicitacaoConsulta", {
          dados: [],
          status: "error",
          message: error.response.data,
          codigo: error.response.status,
        });
      });
  },

  async addSolicitacaoConsultaJuridica({ commit }, solicitacao) {
    commit("setTipoSolicitacao", solicitacao.tipo_solicitacao);
    commit("setItemsSolicitados", solicitacao.itens_solicitados);
    await solicitacaoAmbulatorialService
      .novaSolicitacaoConsultaJuridica(solicitacao)
      .then((solicitacao) => {
        commit("addSolicitacaoConsulta", {
          dados: solicitacao,
          status: "sucesso",
        });
      })
      .catch((error) => {
        console.log(error);
        commit("addSolicitacaoConsulta", {
          dados: [],
          status: "error",
          message: error.response.data,
          codigo: error.response.status,
        });
      });
  },
  addItem({ commit }, item) {
    commit("addItem", item);
  },
  deleteItem({ commit }, itemId) {
    commit("deleteItem", itemId);
  },
};

const mutations = {
  addSolicitacao(state, solicitacao) {
    state.solicitacoes = solicitacao;
  },
  addSolicitacaoConsulta(state, solicitacao) {
    state.solicitacoes = solicitacao;
  },
  clearData(state) {
    state.dadosMedico = {
      status: "",
      dados: [],
    };
    state.dadosPaciente = {
      status: "",
      dados: [],
    };
    state.items = [];
    state.solicitacoes = [];
    state.getTipoSolicitacao = null;
    state.itensSolicitados = [];
    state.tipoSolicitacao = null;
  },
  addItem(state, procedimento) {
    state.items.push(procedimento);
  },
  deleteItem(state, id) {
    state.items = state.items.filter((item) => item.acao !== id);
  },
  addDadosMedico(state, data) {
    state.dadosMedico = data;
  },
  addDadosPaciente(state, data) {
    state.dadosPaciente = data;
  },
  addCid(state, data) {
    state.cid = data;
  },
  addPrioridades(state, data) {
    state.prioridades = data;
  },
  setTipoSolicitacao(state, tipoSolicitacao) {
    state.tipoSolicitacao = tipoSolicitacao;
  },
  setItemsSolicitados(state, itens) {
    state.itensSolicitados = itens;
  },
  deleteAllItems(state) {
    state.items = [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
