<template>
  <header
    class="bg-white position-relative py-2 py-md-0 align-items-center d-flex"
  >
    <div
      class="d-md-flex align-items-center justify-content-center justify-content-md-between h-100 w-100 px-3"
    >
      <div class="d-flex logos align-items-center justify-content-sm-between">
        <router-link to="/">
          <img
            alt="Acessa e confia logo"
            src="@/assets/images/logo-icepi.png"
            class="logo-icepi"
        /></router-link>
        <a href="https://www.gov.br/saude/pt-br" target="_blank">
          <img
            alt="SUS logo"
            src="@/assets/images/sus.svg"
            class="img-fluid logo-sus"
          />
        </a>
        <a href="https://www.es.gov.br/" target="_blank" class="mx-1">
          <img
            alt="Espírito Santo logo"
            src="@/assets/images/governo-espirito-santo.svg"
            class="img-fluid"
          />
        </a>
      </div>
      <div
        v-if="getUser.first_name && $route.name !== 'home'"
        class="align-items-center d-none d-md-flex"
      >
        <img
          :src="getUser.pessoa.avatar"
          class="align-self-baseline rounded-circle mt-1 cursor-pointer"
          width="36"
          alt=""
          @click="openProfile"
        />
        <span class="ml-1 d-flex cursor-pointer" @click="openProfile">
          <span>{{ getNameSocial ? getNameSocial : getFullName }}</span>
        </span>
        <button
          class="btn text-main-secondary fw-semibold d-flex ml-1"
          @click="logout"
        >
          Sair
          <img src="@/assets/images/icons/icon-logoff.svg" alt="" />
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "HeaderGlobal",
  computed: {
    ...mapGetters("auth", ["getUser", "getFullName", "getNameSocial"]),
  },
  props: {
    isDashboard: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    openProfile() {
      this.$router.push({ name: "meuperfil" });
    },
  },
};
</script>

<style lang="sass" scoped>

@import "./styles.scss"


// Estilo para a logo
.logo-icepi
  max-width: 200px

// Estilo para os links de imagem (logos)
a img
  max-width: 100px
</style>
