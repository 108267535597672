import api from "@/services/api";

export default {
  fetchQuadro(payload) {
    return api
      .get(`solicitacoes/quadro/abrir`, {
        params: {
          id_solicitacao: payload.item.id,
          contexto: payload.contexto,
        },
      })
      .then((response) => response.data);
  },
  fetchTiposOpiniao() {
    return api
      .get("profissional_referencia/tipos_opniao")
      .then((response) => response.data);
  },
  postAvaliarSolicitacao(payload) {
    return api
      .post("profissional_referencia/avaliar_solicitacao", payload)
      .then((response) => response.data);
  },
  postDevolucao(payload) {
    return api
      .post("profissional_referencia/formativa_solicitacao", payload)
      .then((response) => response.data);
  },
  postResposta(payload) {
    return api
      .post("solicitante/responder_formativa", payload)
      .then((response) => response.data);
  },
  submitOpinion(payload) {
    return api
      .post("solicitante/responder_formativa", payload)
      .then((response) => response.data);
  },

  fetchModalidades(solicitacao_id) {
    return api.get("/profissional_referencia/modalidade_atendimento", {
      params: {
        solicitacao_id: solicitacao_id,
      },
    });
  },
  atenderPaciente(data) {
    return api.put(`/profissional_referencia/atender_paciente`, data, {
      params: {
        identificador: data.identificador,
      },
    });
  },
  informarLaudo(data) {
    return api.put(`/profissional_referencia/informar_laudo`, data, {
      params: {
        identificador: data.identificador,
      },
    });
  },
  fetchHistoricoAtendimento(params) {
    return api
      .get(`solicitacoes/quadro/historico_atendimento`, {
        params: {
          id_solicitacao: params.id_solicitacao,
        },
      })
      .then((response) => response.data);
  },

  fetchHistoricoLaudos(params) {
    return api
      .get(`solicitacoes/quadro/historico_laudos`, {
        params: {
          id_solicitacao: params.id_solicitacao,
        },
      })
      .then((response) => response.data);
  },
  fetchListaProcedimentos(params) {
    return api
      .get(`solicitacoes/quadro/listar_procedimentos`, {
        params: {
          id_solicitacao: params.id_solicitacao,
        },
      })
      .then((response) => response.data);
  },
};
