import quadroClinicoService from "@/services/quadroClinico.service";
import store from "@/store";

const state = {
  solicitacao_id: null,
  paciente_id: null,
  estab_solicitante_id: null,
  identificador: null,
  paciente: {
    nome: "",
    cns: "",
    cpf: "",
    sexo: "",
    raca_cor: "",
    telefone: "",
    endereco: "",
    cidade: "",
  },
  agendamentoId: "",
  agendar: false,
  solicitante: {
    nome: "",
  },
  quadro: {},
  formativa: [],
  procedimentos_solicitados: [
    {
      procedimento: "",
      situacao: "",
      id: 0,
    },
  ],
  historico_solicitacoes: [],
  historico_atendimento: [],
  historico_laudos: [],
  historico_atendimento_existe: false,
  existe_historico_laudos: false,
  exames_complementares: [
    {
      procedimento: "",
      copia_laudo_id: 0,
    },
  ],
  tipo_solicitacao: "",
  situacao: "",
  isLoading: false,
  tipo: "",
  listar_procedimentos: [],
};

const getters = {
  getSolicitacaoId: (state) => state.solicitacao_id,
  getIdentificador: (state) => state.identificador,
  getPaciente: (state) => state.paciente,
  getSituacao: (state) => state.situacao,
  getFormativa: (state) => state.formativa,
  getHistoricoSolicitacoes: (state) => state.historico_solicitacoes,
  getHistoricoLaudos: (state) => state.historico_laudos,
  getSolicitante: (state) => state.solicitante,
  getQuadro: (state) => state.quadro,
  getProcedimentosSolicitados: (state) => state.procedimentos_solicitados,
  getExamesComplementares: (state) => state.exames_complementares,
  getTipoSolicitacao: (state) => state.tipo_solicitacao,
  // eslint-disable-next-line no-unused-vars
  getModuleDataPage: (state, _, rootState) => {
    return rootState.dataPage;
  },
  getAllTabs(state, getters) {
    let tabs = [];
    const vinculoSelecionado = store.getters["auth/getVinculoSelecionado"];

    if (
      (vinculoSelecionado.perfil === "Profissional de referência" ||
        vinculoSelecionado.perfil === "Profissional executante") &&
      state.situacao === "AGENDADO"
    ) {
      tabs.push({
        title: "Atendimento",
        dados: [],
      });
    }

    if (state.formativa.length > 0) {
      tabs.push({
        title: "Histórico de opiniões",
        dados: state.formativa,
      });
    }
    if (state.quadro) {
      tabs.push({
        title: "Quadro Clínico",
        dados: state.quadro,
      });
    }

    tabs.push({
      title: "Procedimentos Solicitados",
      dados: state.listar_procedimentos,
    });

    if (
      vinculoSelecionado.perfil === "Profissional de referência" &&
      state.situacao === "AGUARDANDO AVALIAÇÃO"
    ) {
      tabs.push({
        title: "Avaliar Solicitação",
        dados: state.exames_complementares,
      });
    }

    if (
      (vinculoSelecionado.perfil === "Profissional de referência" ||
        vinculoSelecionado.perfil === "Solicitante") &&
      state.exames_complementares.length > 0
    ) {
      tabs.push({
        title: "Exames Complementares",
        dados: state.exames_complementares,
      });
    }

    if (
      (vinculoSelecionado.perfil === "Solicitante" &&
        state.situacao !== "FINALIZADO" &&
        state.situacao !== "NEGADO" &&
        state.situacao !== "AGENDADO") ||
      (vinculoSelecionado.perfil === "Coordenador(a) de unidade" &&
        state.situacao !== "FINALIZADO" &&
        state.situacao !== "NEGADO" &&
        state.situacao !== "AGENDADO") ||
      (vinculoSelecionado.perfil === "Coordenação municipal de regulação" &&
        state.situacao !== "FINALIZADO" &&
        state.situacao !== "NEGADO" &&
        state.situacao !== "AGENDADO") ||
      (vinculoSelecionado.perfil === "Coordenação estadual de regulação" &&
        state.situacao !== "FINALIZADO" &&
        state.situacao !== "NEGADO" &&
        state.situacao !== "AGENDADO")
    ) {
      tabs.push({
        title: "Cancelar Solicitação",
        dados: [],
      });
    }

    if (state.historico_atendimento_existe) {
      tabs.push({
        title: "Histórico de atendimento",
        dados: [],
      });
    }
    if (
      (vinculoSelecionado.perfil === "Solicitante" ||
        vinculoSelecionado.perfil === "Coordenador(a) de unidade") &&
      getters.getModuleDataPage.titulo_pagina === "Agendados"
    ) {
      tabs.push({
        title: "Histórico de contatos",
        dados: [],
      });
    }

    if (state.existe_historico_laudos) {
      tabs.push({
        title: "Histórico de laudos",
        dados: [],
      });
    }

    if (getters.getModuleDataPage.titulo_pagina === "Autorizados") {
      tabs.push({
        title: "Desautorizar",
        dados: [],
      });
    }

    return tabs;
  },
};

const actions = {
  async fetchQuadro({ commit }, payload) {
    await quadroClinicoService.fetchQuadro(payload).then((response) => {
      commit("setTipo", payload.item.tipo);
      commit("setSolicitacaoId", payload.item.id);
      commit("setPaciente", response.paciente);
      commit("setSolicitante", response.solicitante);
      commit("setQuadro", response.quadro);
      commit("setProcedimentosSolicitados", response.procedimentos_solicitados);
      commit("setExamesComplementares", response.exames_complementares);
      commit("setHistoricoSolicitacoes", response.historico_solicitacoes);
      commit("setExisteHistoricoAtendimento", response.historico_atendimento);
      commit("setSituacao", response.situacao);
      commit("setFormativa", response.formativa);
      commit("setIdentificador", payload.item.identificador);
      commit("setPacienteId", response.paciente_id);
      commit("setEstabSolicitanteId", response.estab_solicitante_id);
      commit("setExisteHistoricoLaudos", response.historico_laudos);
      commit("setDesautorizar", response.desautorizar);
    });
  },
  async fetchHistoricoAtendimento({ commit, state }, params) {
    state.isLoading = true;
    await quadroClinicoService
      .fetchHistoricoAtendimento(params)
      .then((response) => {
        commit("setHistoricoAtendimento", response);
      })
      .finally(() => {
        state.isLoading = false;
      });
  },

  async fetchHistoricoLaudos({ commit, state }, params) {
    state.isLoading = true;
    await quadroClinicoService
      .fetchHistoricoLaudos(params)
      .then((response) => {
        commit("setHistoricoLaudos", response);
      })
      .finally(() => {
        state.isLoading = false;
      });
  },
  async fetchListaProcedimentos({ commit, state }, params) {
    state.isLoading = true;
    await quadroClinicoService
      .fetchListaProcedimentos(params)
      .then((response) => {
        commit("setListaProcedimentos", response);
      })
      .finally(() => {
        state.isLoading = false;
      });
  },
};

const mutations = {
  setPaciente(state, payload) {
    state.paciente = payload;
  },
  setSolicitante(state, payload) {
    state.solicitante = payload;
  },
  setSituacao(state, situacao) {
    state.situacao = situacao;
  },
  setFormativa(state, formativa) {
    state.formativa = formativa;
  },
  setQuadro(state, payload) {
    state.quadro = payload;
  },
  setProcedimentosSolicitados(state, payload) {
    state.procedimentos_solicitados = payload;
  },
  setExamesComplementares(state, payload) {
    state.exames_complementares = payload;
  },
  setHistoricoSolicitacoes(state, payload) {
    state.historico_solicitacoes = payload;
  },
  setSolicitacaoId(state, payload) {
    state.solicitacao_id = payload;
  },
  setIdentificador(state, payload) {
    state.identificador = payload;
  },
  setExisteHistoricoAtendimento(state, payload) {
    state.historico_atendimento_existe = payload;
  },
  setExisteHistoricoLaudos(state, payload) {
    state.existe_historico_laudos = payload;
  },
  setHistoricoAtendimento(state, payload) {
    state.historico_atendimento = payload;
  },
  setHistoricoLaudos(state, payload) {
    state.historico_laudos = payload;
  },
  setPacienteId(state, payload) {
    state.paciente_id = payload;
  },
  setEstabSolicitanteId(state, payload) {
    state.estab_solicitante_id = payload;
  },
  setTipo(state, payload) {
    state.tipo = payload;
  },
  setDesautorizar(state, payload) {
    state.desautorizar = payload;
  },
  setListaProcedimentos(state, payload) {
    state.listar_procedimentos = payload;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
