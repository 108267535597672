<template>
  <aside
    :class="
      'sidebarLeft d-none d-lg-flex flex-column align-items-center ' +
      (changeView ? ' open' : '')
    "
  >
    <button
      class="btn d-flex flex-column align-items-center my-4"
      @click="openSidebarLeft"
    >
      <img src="@/assets/images/icons/icon-menu.svg" alt="" />
      Menu
    </button>
    <div class="divider"></div>

    <nav class="my-3 d-block w-100">
      <MenuGeneral :changeView="changeView" />
    </nav>
    <div class="divider mb-2"></div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";
import MenuGeneral from "@/components/SidebarLeft/Menu/MenuGeneral.vue";

export default {
  name: "SidebarLeft",
  components: {
    MenuGeneral,
  },
  data() {
    return {
      changeView: localStorage.getItem("is_expanded") === "true",
      searchSidebar: "",
    };
  },
  computed: {
    ...mapGetters("auth", ["getVinculoSelecionado"]),
  },
  methods: {
    openSidebarLeft() {
      this.changeView = !this.changeView;
      this.$emit("input", this.changeView);
      localStorage.setItem("is_expanded", this.changeView);
    },
    // searchInSidebar() {
    //   console.log(this.searchSidebar);
    // },
  },
};
</script>

<style lang="scss" scoped src="./styles.scss" />
