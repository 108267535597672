import api from "@/services/api";

const state = () => ({
  pesquisa: "NSoAJi1U0",
  pesquisas: [],
  statusPesquisa: null,
  statusCriacaoPesquisa: null,
  nanoId: null,
});

const getters = {
  getPesquisa(state) {
    return state.pesquisa;
  },
  getPesquisas(state) {
    return state.pesquisas;
  },
  getStatusPesquisa(state) {
    return state.statusPesquisa;
  },
  getStatusCriacaoPesquisa(state) {
    return state.statusCriacaoPesquisa;
  },
  getNanoId(state) {
    return state.nanoId;
  },
};

const actions = {
  async fetchPesquisa({ commit, state }) {
    const response = await api
      .get(`nps/pesquisas/${state.nanoId}`)
      .catch((err) => {
        console.log(err);
      });
    if (response && response.data) {
      commit("setPesquisa", response.data);
    }
  },

  async postResposta({ commit }, payload) {
    console.log(payload);
    const response = await api.post("nps/respostas", payload).catch((err) => {
      console.log(err);
      commit("setStatusPesquisa", "erro");
    });

    if (response.status == 200) {
      commit("setStatusPesquisa", "sucesso");
    } else {
      commit("setStatusPesquisa", "erro");
    }
  },
  // isso aqui ta horroroso
  async criarPesquisa({ commit, state }, payload) {
    const response = await api
      .post("nps/pesquisas2/criar", payload)
      .catch((err) => {
        console.log(err);
        commit("setStatusCriacaoPesquisa", response.status);
      });
    if (response && response.data) {
      commit("setNanoId", response.data.nanoid);
      commit("setStatusCriacaoPesquisa", response.status);
      const data = {
        pesquisa_nanoid: state.nanoId,
        perguntas: payload.perguntas,
      };
      console.log(data);
      const response2 = await api
        .post("nps/pesquisas2/criar_pergunta", data)
        .catch((err) => {
          console.log(err);
        });
      if (response2 && response.data) {
        console.log("Perguntas Criadas");
      }
    }
  },

  async limpaVuexCriacaoPesquisa({ commit }) {
    commit("setStatusCriacaoPesquisa", null);
  },

  async fetchTodasPesquisas({ commit }) {
    console.log("chegou");
    const response = await api.get("nps/pesquisas").catch((err) => {
      console.log(err);
    });
    if (response && response.data) {
      commit("setPesquisas", response.data.pesquisas);
    }
  },

  async salvarNanoId({ commit }, payload) {
    commit("setNanoId", payload);
  },
};

const mutations = {
  setPesquisas(state, pesquisas) {
    state.pesquisas = pesquisas;
  },
  setPesquisa(state, pesquisa) {
    state.pesquisa = pesquisa;
  },
  setStatusPesquisa(state, status) {
    state.statusPesquisa = status;
  },
  setStatusCriacaoPesquisa(state, status) {
    state.statusCriacaoPesquisa = status;
  },

  setNanoId(state, nanoId) {
    state.nanoId = nanoId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
