var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-white1 min-vh-100 pb-5 pb-md-0 d-flex w-100"},[_c('keepAlive',[_c('SidebarLeft',{model:{value:(_vm.changeView),callback:function ($$v) {_vm.changeView=$$v},expression:"changeView"}})],1),_c('BottomBar'),_c('div',{staticClass:"w-100 pb-5"},[_c('div',{class:`aux-header ${_vm.changeView ? 'open-aux' : ''}`},[_c('keepAlive',[_c('HeaderGlobal',{staticClass:"position-relative",attrs:{"isDashboard":_vm.isDashboard}})],1),_c('KeepAlive',[_c('SubHeader')],1)],1),_c('section',{class:`container-fluid px-md-3 wrapper pb-3 ${
        _vm.changeView ? 'open-aux' : ''
      }`},[_c('div',{class:`mx-auto  ${
          _vm.$route.name === 'formularioSolicitacao' ||
          _vm.$route.name === 'formularioConsulta'
            ? 'container'
            : 'max-1700'
        }`},[_c('router-view')],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }