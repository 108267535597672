import api from "@/services/api";

export default {
  async postContrato(payload) {
    return await api
      .post("contrato/novo", payload)
      .then((response) => response.data);
  },
  async fetchContratos() {
    return await api.get("contrato/listar").then((response) => response.data);
  },
  async getPermissao(id) {
    return await api
      .get("contrato/pode_editar_item", {
        params: {
          item_id: id,
        },
      })
      .then((response) => response.data);
  },
  async removerReferencia(id) {
    return await api
      .put(`contrato/editar_item?item_id=${id}`, {
        exclusao: true,
      })
      .then((response) => response.data);
  },
  async dadosContrato(id) {
    return await api
      .get(`contrato/dados?contrato_id=${id}`)
      .then((response) => response.data);
  },
  async abrirQuadro(id) {
    return await api
      .get(`contrato/item/dados?item_id=${id}`)
      .then((response) => response.data);
  },
  async itemDados(id) {
    return await api
      .get(`contrato/item_dados?item_id=${id}`)
      .then((response) => response.data);
  },
  async editItem(data) {
    let dados = data.data;
    return await api
      .put(`contrato/editar_item?item_id=${data.item_id}`, dados)
      .then((response) => response.data);
  },

  adicionarItem(data, contrato_id) {
    return api.put(`contrato/inserir_item?contrato_id=${contrato_id}`, data);
  },
};
