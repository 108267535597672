<template>
  <div class="h-100-mb">
    <ul class="list-unstyled list-menu">
      <li v-for="item in buildMenu" :key="item.name" @click="toggleMenu">
        <router-link
          :to="item.url"
          :class="`d-flex align-items-center ${
            item.name !== 'Suporte' ? 'd-flex' : 'd-lg-none'
          } ${changeView ? 'show-text' : ''}`"
        >
          <img
            :src="require(`@/assets/images/icons/${item.icon}`)"
            alt=""
            width="20"
          />
          <span class="white-space-normal" style="white-space: normal">
            {{ item.name }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import detectingMobile from "@/mixins/detectingMobile";

export default {
  props: {
    changeView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      menuItems: [
        {
          name: "Painel",
          icon: "icon-home.svg",
          url: "/dashboard",
          perfil: ["Solicitante", "Jurídico"],
        },
        {
          name: "Painel",
          icon: "icon-home.svg",
          url: "/nps/score/painel_nps",
          perfil: ["Ouvidor(a)", "Contratualização"],
        },
        {
          name: "Grade Referência",
          icon: "icons8-user-male.svg",
          url: "/gradeunireferencia",
          perfil: ["Solicitante", "Jurídico"],
        },
        {
          name: "Solicitações",
          icon: "icon-item2.svg",
          url: "/dashboard/lista-solicitacao",
          perfil: ["administrador"],
        },
        {
          name: "Agenda",
          icon: "icon-calendar.svg",
          url: "/dashboard/agenda",
          perfil: ["administrador"],
        },
        {
          name: "Item 1",
          icon: "icon-item4.svg",
          url: "/",
          perfil: ["administrador"],
        },
        {
          name: "Item 2",
          icon: "icon-item5.svg",
          url: "/",
          perfil: ["administrador"],
        },
        {
          name: "Item 3",
          icon: "icon-item6.svg",
          url: "/",
          perfil: ["administrador"],
        },
        {
          name: "Item 4",
          icon: "icon-item7.svg",
          url: "/",
          perfil: ["administrador"],
        },
        {
          name: "Home",
          icon: "icon-home.svg",
          url: "/lista-vinculos",
          perfil: ["Coordenador(a) de unidade"],
        },

        {
          name: "Home",
          icon: "icon-home.svg",
          url: "/profissional_referencia",
          perfil: ["Profissional de referência"],
        },
        {
          name: "Home",
          icon: "icon-home.svg",
          url: "/suporte",
          perfil: ["Suporte"],
        },

        {
          name: "Vínculos",
          icon: "icon-vinculos.svg",
          url: "/lista-vinculos",
          perfil: [
            "Coordenação estadual de regulação",
            "Coordenação municipal de regulação",
          ],
        },
        {
          name: "Contratos",
          icon: "file-invoice-solid.svg",
          url: "/contratroscobertura",
          perfil: [
            "Coordenação estadual de regulação",
            "Coordenação municipal de regulação",
          ],
        },
        {
          name: "Consultas",
          icon: "consulta-icon.svg",
          url: "/consultasespecializadas",
          perfil: ["Coordenação estadual de regulação"],
        },
        {
          name: "Sigtap",
          icon: "icon-sigtap.png",
          url: "/sigtap",
          perfil: ["Coordenação estadual de regulação"],
        },
        {
          name: "Suporte",
          icon: "icon-help.svg",
          url: "/contatosuporte",
          perfil: ["Todos"],
        },
        {
          name: "Credenciamento",
          icon: "icon-card-business.svg",
          url: "/credenciamento",
          perfil: ["Solicitante", "Jurídico"],
        },

        {
          name: "Solicitações Encerradas",
          icon: "icon-solicitacoes-encerradas.svg",
          url: "/solicitacoes-encerradas",
          perfil: [
            "Solicitante",
            "Coordenação estadual de regulação",
            "Coordenação municipal de regulação",
            "Coordenador(a) de unidade",
          ],
        },

        {
          name: "Listar Agenda",
          icon: "icon-agenda.svg",
          url: "/listaragenda",
          perfil: ["Responsável agenda"],
        },
        {
          name: "Cadastrar preparo",
          icon: "icon-preparo.svg",
          url: "/cadastrarpreparo",
          perfil: ["Responsável agenda"],
        },
        {
          name: "Dados do estabelecimento",
          icon: "house-medical-solid.svg",
          url: "/dados-estabelecimento",
          perfil: ["Coordenador(a) de unidade"],
        },
        {
          name: "Acompanhamento",
          icon: "bars-staggered-solid.svg",
          url: "/acompanhamento-solicitacoes",
          perfil: [
            "Coordenação estadual de regulação",
            "Coordenação municipal de regulação",
            "Coordenador(a) de unidade",
          ],
        },
        {
          name: "Painel NPS",
          icon: "star-half-stroke-regular.svg",
          url: "/nps/score/painel_nps",
          perfil: ["Coordenação estadual de regulação"],
        },
        {
          name: "Relatório de envio NPS",
          icon: "icon-relatorio-aplicacao.svg",
          url: "/nps/score/relatorio_aplicacao",
          perfil: [
            "Coordenação estadual de regulação",
            "Ouvidor(a)",
            "Contratualização",
          ],
        },
      ],
    };
  },
  mixins: [detectingMobile],
  computed: {
    ...mapGetters("auth", ["getVinculoSelecionado"]),
    buildMenu() {
      return this.menuItems.filter((item) => {
        return (
          item.perfil.includes(this.getVinculoSelecionado.perfil) ||
          item.perfil.includes("Todos")
        );
      });
    },
  },
  methods: {
    toggleMenu() {
      if (screen.width <= 780) {
        this.$parent.openMenu();
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

/* Skewing effect on menu links */
.main-nav ul {
  display: inline-flex;
  flex-direction: column;
  height: 93%; /* Should be 100%, but we have a notice message :D */
  align-items: flex-end;
  justify-content: center;
  transform: translateX(-18%) skew(-16deg);
}

.main-nav li {
  display: block;
  margin: 0.5rem 0;
  text-align: right;
  transform: skew(16deg);
}

/* Apparition effect on links */
.main-nav a {
  opacity: 0;
  transform: translateY(-10px);
}

.main-nav.is-open a {
  opacity: 1;
  transform: translateY(0);
}

.main-nav li:nth-child(1) a {
  transition: all 275ms 175ms;
}

.main-nav li:nth-child(2) a {
  transition: all 275ms 225ms;
}

.main-nav li:nth-child(3) a {
  transition: all 275ms 275ms;
}

.main-nav li:nth-child(4) a {
  transition: all 275ms 325ms;
}

.main-nav li:nth-child(5) a {
  transition: all 275ms 375ms;
}

/* Decoration */
.main-nav ul,
.main-nav li {
  list-style: none;
  padding: 0;
}

.main-nav a {
  display: block;
  padding: 12px 0;
  color: $main-secondary;
  font-size: 1.4em;
  text-decoration: none;
  font-weight: bold;
}

.open-main-nav {
  position: fixed;
  bottom: 7px;
  padding-top: 20px;
  right: 15px;
  z-index: 1000;
  background: none;
  border: 0;
  cursor: pointer;
}

.open-main-nav:focus {
  outline: none;
}

/* Toggle State part */
.is-open .burger {
  transform: skew(5deg) translateY(-8px) rotate(-45deg);
}

.is-open .burger:before {
  transform: translateY(0px) skew(-10deg) rotate(75deg);
}

.is-open .burger:after {
  transform: translateY(-12px) translateX(10px) skew(-20deg);
  opacity: 0;
}

.h-100-mb {
  height: 100vh;
  @include breakpoint(lg) {
    height: auto;
  }
}

@include breakpoint(lg) {
  .list-menu {
    li {
      &:not(last-child) {
        margin-bottom: 16px;
      }

      &:hover {
        background-color: $green-light;
      }

      a {
        height: 40px;
        transition: all 0.1s ease-in-out;
        position: relative;

        &.router-link-exact-active {
          border-right: 3px solid $green1;
        }

        &:hover {
          text-decoration: none;
          border-right: 3px solid $green1;
        }
      }

      img {
        transition: all 0.3s ease-in-out;
        margin-left: 13px;
      }

      span {
        opacity: 0;
        margin-left: 5px;
        color: $main-secondary;
        transition: all 0.3s ease-in-out;
        white-space: nowrap;
      }

      .show-text img {
        margin-left: 25px;
      }

      .show-text span {
        opacity: 1;
      }
    }
  }
}
</style>
