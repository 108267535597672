import api from "@/services/api";

const state = {
  data: {},
  meus_pacientes: false,
  titulo_pagina: "",
  urlAtual: null,
  currentPage: 1,
  size: 10,
  total: 10,
  href_lista: "/lista_andamento",
  fields: [],
  showBackButton: false,
  atualizarDadosPagina: false,
  isLoading: false,
  update: false,
  filter: null,
};

const getters = {
  getData: (state) => state.data,
  getTituloPagina: (state) => state.titulo_pagina,
  getShowBackButton: (state) => state.showBackButton,
  getUrl: (state) => state.urlAtual,
  getAtualizacaoDadosPagina: (state) => state.atualizarDadosPagina,
};

const actions = {
  async atualizarTabela({ commit, state }) {
    state.isLoading = true;
    await api
      .get(`${state.urlAtual}`, {
        params: {
          meus_pacientes: state.meus_pacientes,
          page: state.currentPage,
          size: state.size,
        },
      })
      .then((response) => {
        commit("setData", response.data.items);
      })
      .finally(() => {
        state.isLoading = false;
        state.update = !state.update;
      });
  },

  async fetchData({ commit, state }, payload) {
    state.filter = "";
    state.isLoading = true;
    commit("setData", {});
    if (payload.type === "cards" || payload.type === "dashboard") {
      commit("setUrlAtual", payload.href);
      commit("setHrefLista", payload.href_lista);
      commit("setTituloPagina", payload.title);
      commit("setMeusPacientes", payload.meus_pacientes);
      commit("setShowBackButton", payload.showBackButton);
    }
    if (payload.type === "radio") {
      commit("setMeusPacientes", payload.meus_pacientes);
    }
    await api
      .get(`${state.urlAtual}`, {
        params: {
          meus_pacientes: state.meus_pacientes,
          page: state.currentPage,
          size: state.size,
        },
      })
      .then((response) => {
        commit("setData", response.data.items);
        commit("setTotalPage", response.data.total);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        state.isLoading = false;
        state.update = !state.update;
      });

    commit("setAtualizarDadosPagina");
  },
  async updateTable({ commit, state }, currentPage) {
    state.isLoading = true;
    await api
      .get(`${state.urlAtual}`, {
        params: {
          meus_pacientes: state.meus_pacientes,
          page: currentPage,
          size: state.size,
        },
      })
      .then((response) => {
        commit("setData", response.data.items);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        state.isLoading = false;
        state.update = !state.update;
      });
  },
  async updateTablePerPage({ commit, state }, perPage) {
    state.isLoading = true;
    await api
      .get(`${state.urlAtual}`, {
        params: {
          meus_pacientes: state.meus_pacientes,
          page: state.currentPage,
          size: perPage,
        },
      })
      .then((response) => {
        commit("setData", response.data.items);
        commit("setTotalPage", response.data.total);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        state.isLoading = false;
        state.update = !state.update;
      });
  },
  async filterTable({ commit, state }, filter) {
    state.isLoading = true;
    await api
      .get(`${state.urlAtual}`, {
        params: {
          meus_pacientes: state.meus_pacientes,
          page: state.currentPage,
          size: state.size,
          q: filter,
        },
      })
      .then((response) => {
        commit("setData", response.data.items);
        commit("setTotalPage", response.data.total);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        state.isLoading = false;
        state.update = !state.update;
      });
  },
};

const mutations = {
  setMeusPacientes(state, data) {
    state.meus_pacientes = data;
  },
  setData(state, data) {
    state.data = data;
  },
  setAtualizarDadosPagina(state) {
    state.atualizarDadosPagina = !state.atualizarDadosPagina;
  },
  setTituloPagina(state, titulo) {
    state.titulo_pagina = titulo;
  },
  setUrlAtual(state, url) {
    state.urlAtual = url;
  },
  setHrefLista(state, href) {
    state.href_lista = href;
  },
  setFields(state, data) {
    state.fields = data.fields;
  },
  setShowBackButton(state, data) {
    state.showBackButton = data;
  },
  setCurrentPage(state, data) {
    state.currentPage = data;
  },
  setUpdateTable(state) {
    state.updateTable = !state.updateTable;
  },
  setPerPage(state, data) {
    state.size = data;
  },
  setTotalPage(state, data) {
    state.total = data;
  },
  setFilter(state, data) {
    state.filter = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
